import React, { useState, useEffect } from "react";
import {
  CRUDLayout, Input, InputSearch,
  CreateButton, ActionButton, DataStatus,
  CreateModal, UpdateModal, DeleteModal,
  UpdateButton, DeleteButton, Switch,
  Th, Td, Alert, Pagination, THead,
  TBody, ThFixed, TdFixed, Tr,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import { KondisiAsetApi } from "../../../api";
import { PageNumber } from "../../../utilities";
import { ModalBody, ModalFooter, Row, Col } from "react-bootstrap";

const KondisiAset = ({ setNavbarTitle }) => {
  const title = "Master Kondisi Aset";
  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [dataCount, setDataCount] = useState(0)
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false)

    KondisiAsetApi.getPage(page, dataLength, searchKey)
      .then(res => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
        setDataCount(res.data.data_count)
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfigSearch({
            data: true,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: "",
            text: "",
          });
        }
  
        setIsLoading(false)
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  // TODO: CHECK SWITCH SHOW HIDDEN BUG API
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_kondisi_aset: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? KondisiAsetApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : KondisiAsetApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    nama_kondisi_aset: Yup.string().required("Masukkan Nama Kondisi Aset")
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      nama_kondisi_aset: "",
      keterangan: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      KondisiAsetApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Input
                  label="Nama Kondisi Aset"
                  type="text"
                  name="nama_kondisi_aset"
                  placeholder="Kondisi Aset"
                  value={values.nama_kondisi_aset}
                  onChange={handleChange}
                  error={errors.nama_kondisi_aset && touched.nama_kondisi_aset && true}
                  errorText={errors.nama_kondisi_aset}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  loading={isSubmitting}
                />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_kondisi_aset: updateData.id_kondisi_aset,
      nama_kondisi_aset: updateData.nama_kondisi_aset,
      keterangan: updateData.keterangan,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      // SEND UPDATED DATA TO SERVER
      KondisiAsetApi.update(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Input
                  label="Nama Kategori Aset"
                  type="text"
                  name="nama_kondisi_aset"
                  placeholder="Kategori Aset"
                  value={values.nama_kondisi_aset}
                  onChange={handleChange}
                  error={errors.nama_kondisi_aset && touched.nama_kondisi_aset && true}
                  errorText={errors.nama_kondisi_aset}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  loading={isSubmitting}
                />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_kondisi_aset: deleteData.id_kondisi_aset };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      KondisiAsetApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPIL ALERT
          setShowAlert(true);
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nama Kondisi Aset : {deleteData.nama_kondisi_aset}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Kondisi Aset</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_kondisi_aset}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <UpdateButton
                        onClick={() => {
                          setUpdateData(val);
                          setIsUpdateForm(true);
                        }}
                      />
                      <DeleteButton
                        onClick={() => {
                          setDeleteData(val);
                          setIsDeleteData(true);
                        }}
                      />
                      <Switch
                        id={val.id_kondisi_aset}
                        checked={val.is_hidden ? false : true}
                        onChange={() => changeDataStatus(val.is_hidden, val.id_kondisi_aset)}
                      />
                    </div>
                  </TdFixed>
                  <Td>{val.nama_kondisi_aset}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setPage(1)
              setDataLength(e.target.value)
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value)
                    setPage(1)
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setAlertConfigSearch({
          data: false,
          variant: "primary",
          text: "",
        })}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default KondisiAset;