import Services from "../../../../services";

class AsetBangunanApi {
  getPage(page, dataLength, key) {
    return Services.get(`/asetbangunan/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getKode() {
    return Services.get("/asetbangunan/no_baru?id_kategori_aset=19");
  }

  getKategoriAset() {
    return Services.get("/asetbangunan/kategori");
  }

  getKaryawan() {
    return Services.get("/karyawan/dropdown");
  }

  getUnitOrganisasi() {
    return Services.get("/unit_organisasi/dropdown");
  }

  getProvinsi() {
    return Services.get("/wilayah/dropdown_provinsi");
  }

  getKabupaten(id) {
    return Services.get("/wilayah/dropdown_kabupaten?id_provinsi=" + id);
  }

  getKecamatan(id) {
    return Services.get("/wilayah/dropdown_kecamatan?id_kabupaten=" + id);
  }

  getDesa(id) {
    return Services.get("/wilayah/dropdown_desa?id_kecamatan=" + id);
  }

  getSingle(id_item_aset) {
    return Services.get("/asetbangunan/single/?id_item_aset=" + id_item_aset);
  }

  create(data) {
    return Services.post("/asetbangunan", data);
  }

  update(data) {
    return Services.put("/asetbangunan", data);
  }

  delete(id) {
    return Services.post("/asetbangunan/delete", id);
  }

  show(id) {
    return Services.put("/asetbangunan/show", id);
  }

  hide(id) {
    return Services.put("/asetbangunan/hide", id);
  }

  getGolongan() {
    return Services.get("/asetbangunan/dropdown_golongan_aset");
  }

  getKondisi() {
    return Services.get("/asetbangunan/dropdown_kondisi_aset");
  }

  getJenis() {
    return Services.get("/asetbangunan/dropdown_jenis_aset");
  }
  uploadFileMultiple(data) {
    return Services.post("/upload/aset_multiple", data);
  }

  uploadFile(data) {
    return Services.post("/asetbangunan/gambar", data);
  }

  deleteFile(id) {
    return Services.post("/asetbangunan/gambar_delete", id);
  }
  
  dropdown(value){
    return Services.get("/asetbangunan/dropdown", {params: {...value}});
  }

  single(value){
    return Services.get("/asetbangunan/single", {params: {...value}});
  }

  no_baru(value) {
    return Services.get("/asetbangunan/no_baru", {params: {...value}});
  }

  penyusutan(value) {
    return Services.get("/penyusutan_saldo_menurun/penyusutan", {params: {...value}});
  }

  updatePenyusutan(value) {
    return Services.put("/asetbangunan/penyusutan", value);
  }

  singleCheck(value){
    return Services.get("/asetbangunan/single_check", {params: {...value}});
  }
}

export default new AsetBangunanApi();
