import React, { 
  useState, 
  useEffect 
} from "react"
import { 
  useHistory, 
  useLocation 
} from "react-router-dom"
import { 
  Row, 
  Col, 
  ModalBody
} from "react-bootstrap"
import {
  IoAddOutline,
  IoEyeOutline
} from 'react-icons/io5'
import _ from "lodash"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  BackButton,
  Modal,
} from "components"
import { 
  DateConvert, 
  PageNumber as TableNumber 
} from "utilities"
import { 
  TransferProduksiAlatMesinApi
} from "api"
import { InfoItemHorizontal } from "components"

const ListTransferProduksiAlatMesin = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataSalesOrder, setDataSalesOrder] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {}
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    TransferProduksiAlatMesinApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataSalesOrder(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase()
    if (newStatus === "REJ") {
      return "REJECTED"
    }

    if (newStatus === "REV") {
      return "REVISI"
    }

    if (newStatus === "APP") {
      return "APPROVED"
    }

    if (newStatus === "VER") {
      return "VERIFIED"
    }

    return "PENDING"
  }

  useEffect(() => {
    setNavbarTitle("List Transfer Alat & Mesin")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ])

  const DetailModal = () => {
    const dataInfo = modalConfig.data
    return (
      <Modal
        closeButton
        size="md"
        title="Detail Transfer Alat & Mesin Job Mix"
        show={modalConfig.show}
        onHide={() => setModalConfig({
          show: false,
          data: {}
        })}
      >
        <ModalBody>
          <InfoItemHorizontal 
            label="Tgl. Transfer Alat & Mesin"
            text={dataInfo.tgl_jobmix_transfer_alat_mesin ? DateConvert(new Date(dataInfo.tgl_jobmix_transfer_alat_mesin)).detail : '-'}
            className="mb-2"
          />
          <InfoItemHorizontal 
            label="No. Transfer Alat & Mesin"
            text={dataInfo.no_jobmix_transfer_alat_mesin ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal 
            label="No. Permintaan Job Mix"
            text={dataInfo.no_jobmix_permintaan ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal 
            label="Item Alat & Mesin"
            text={dataInfo.nama_item_aset ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal 
            label="Qty. Transfer Alat & Mesin"
            text={dataInfo.qty_transfer ? `${parseFloat(dataInfo.qty_transfer)} ${dataInfo.nama_satuan_pakai}` : '-'}
            className="mb-2"
          />
          <InfoItemHorizontal 
            label="Nama Pabrik"
            text={dataInfo.nama_pabrik ?? '-'}
            className="mb-2"
          />
        </ModalBody>
      </Modal>
    )
  }

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th width={150}>Tgl. Transfer Alat & Mesin</Th>
            <Th width={150}>No. Transfer Alat & Mesin</Th>
            <Th width={120}>No. Permintaan Job Mix</Th>
            <Th>Item Alat & Mesin</Th>
            <Th width={120}>Qty. Transfer Alat & Mesin</Th>
            <Th>Pabrik</Th>
          </tr>
        </thead>
        <tbody>
          {dataSalesOrder.map((val, index) => (
            <tr key={index}>
              <Td textCenter>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
              <Td>
                <ActionButton 
                  size="sm"
                  onClick={() => setModalConfig({
                    show: true,
                    data: val
                  })}
                >
                  <IoEyeOutline />
                </ActionButton>
              </Td>
              <Td>{val.tgl_jobmix_transfer_alat_mesin ? DateConvert(new Date(val.tgl_jobmix_transfer_alat_mesin)).defaultDMY : "-"}</Td>
              <Td>{val.no_jobmix_transfer_alat_mesin ?? "-"}</Td>
              <Td>{val.no_jobmix_permintaan ?? "-"}</Td>
              <Td>{val.nama_item_aset ?? "-"}</Td>
              <Td>{val.qty_transfer ? `${parseFloat(val.qty_transfer)} ${val.nama_satuan_pakai}` : '-'}</Td>
              <Td>{val.nama_pabrik ?? "-"}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    )

    if (!dataSalesOrder || dataSalesOrder.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ActionButton 
            text="Tambah Data"
            onClick={() => history.push('/transaksi/transfer-produksi-alat-mesin/permintaan')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}

      {/* Modal */}
      <DetailModal />
    </CRUDLayout>
  )
}

export default ListTransferProduksiAlatMesin
