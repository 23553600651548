import React from 'react'
import { 
  DataStatus,
  Table, 
  Td,
  TdFixed,
  Th,
  ThFixed
} from 'components'
import { 
  DateConvert 
} from 'utilities'

const TableHistory = ({dataHistory}) => {
  return (
    <div>
      <div className="py-2">
        <b>History Transfer Alat & Mesin Job Mix</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th width={100}>Tgl. Transfer</Th>
            <Th width={100}>No. Transfer</Th>
            <Th>Item Alat & Mesin</Th>
            <Th width={100}>Qty. Transfer</Th>
          </tr>
        </thead>
        <tbody>
          {dataHistory && dataHistory.length > 0
            ? dataHistory.map((val, index) => (
              <tr>
                <TdFixed textCenter className="pb-2">{index + 1}</TdFixed>
                <Td>{val.tgl_transfer ? DateConvert(new Date(val.tgl_transfer)).defaultDMY : ''}</Td>
                <Td>{val.no_transfer}</Td>
                <Td>{val.nama_item}</Td>
                <Td textRight>{val.qty_transfer} {val.satuan}</Td>
              </tr>
            ))
            : <tr>
                <Td colSpan={5}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
          }
        </tbody>
      </Table>
    </div>
  )
}

export default TableHistory
