import React, { useState, useEffect } from 'react'
import {
    CRUDLayout,
    Input,
    InputSearch,
    ExportButton,
    CreateButton,
    ActionButton,
    DataStatus,
    CreateModal,
    UpdateModal,
    DeleteModal,
    UpdateButton,
    DeleteButton,
    Switch,
    Alert,
    Pagination,
    THead,
    TBody,
    ThFixed,
    TdFixed,
    Tr,
    Th,
    Td
} from '../../../components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import { GrupAsetApi } from '../../../api'
import { PageNumber } from '../../../utilities'

const GrupAset = ({ setNavbarTitle }) => {

    // STATE LOADING 
    const [isLoading, setIsLoading] = useState(true);

    // STATE SEARCHING
    const [isSearching, setIsSearching] = useState(false);
    const [searchKey, setSearchKey] = useState('');

    // MODALS SHOW STATE
    const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
    const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
    const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

    // STATE DATA SATUAN
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [dataLength, setDataLength] = useState(10);
    const [dataCount, setDataCount] = useState(0)
    const [totalPage, setTotalPage] = useState(1);
    // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
    const [updateData, setUpdateData] = useState([]);
    // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
    const [deleteData, setDeleteData] = useState([]);

    // ALERT STATE
    const [showAlert, setShowAlert] = useState(false);
    // KONFIGURASI ALERT
    const [alertConfig, setAlertConfig] = useState({
        variant: "primary",
        text: "",
    });

    // FUNCTION GET DATA DARI SERVER
    const getData = () => {
        setIsLoading(true);
        setIsSearching(false);
        setSearchKey('');

        Axios.all([GrupAsetApi.getPage(page, dataLength)]).then(
            Axios.spread((res) => {
                setData(res.data.data);
                setTotalPage(res.data.total_page);
            })
        )
            .catch((err) => alert(err))
            .finally(() => setIsLoading(false))
    }

    // FUNCTION SWITCH HIDDEN DATA
    const changeDataStatus = (status, id) => {
        setIsLoading(true);
        setShowAlert(false);

        const value = {
            id_grup_aset: id,
        };

        const onLoadedSuccess = () => {
            setIsSearching(false);
            setAlertConfig({
                variant: "primary",
                text: "Ubah status data berhasil",
            });
            setShowAlert(true);
        };

        const onLoadedFailed = () => {
            setIsSearching(false);
            setAlertConfig({
                variant: "danger",
                text: "Ubah status data gagal",
            });
            setShowAlert(true);
        };

        status === true
            ? GrupAsetApi.show(value)
                .then(() => onLoadedSuccess())
                .catch(() => onLoadedFailed())
                .finally(() => getData())
            : GrupAsetApi.hide(value)
                .then(() => onLoadedSuccess())
                .catch(() => onLoadedFailed())
                .finally(() => getData());

    }

    // FUNCTION CARI DATA DARI SERVER 
    const searchData = (e) => {
        e.preventDefault();
        setIsLoading(true);

        const key = searchKey  // SearchKey Value as key

        // SEARCH DATA WITH KEY FROM DATABASE
        GrupAsetApi.search(key)
            .then((res) => {
                setData(res.data.data);
                setTotalPage(res.data.total_page);
            })
            .catch((err) => alert(err))
            .finally(() => {
                setIsSearching(true);
                setIsLoading(false);
                setAlertConfig({
                    variant: "primary",
                    text: `Hasil Pencarian : ${key}`,
                });
                setShowAlert(true);
            });
    }

    // ON COMPONENT MOUNT
    useEffect(() => {
        setNavbarTitle('Master Grup Aset'); // SET JUDUL NAVBAR
        getData();  // GET DATA DARI SERVER

        return () => {
            setIsLoading(false);
            setIsSearching(false);
        }
    }, [setNavbarTitle, page, dataLength])

    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
        kode_grup_aset: Yup.string()
            .required("Masukkan kode grup aset"),
        nama_grup_aset: Yup.string().required("Masukkan nama grup aset"),
        keterangan: Yup.string().required('Masukkan keterangan')
    });

    // MODAL TAMBAH COMPONENT
    const TambahModal = () => {

        // FORMIK INITIAL VALUES
        const formInitialValues = {
            kode_grup_aset: "",
            nama_grup_aset: "",
            keterangan: "",
        };

        // KIRIM DATA BARU KE SERVER
        const formSubmitHandler = (values) => {

            GrupAsetApi.create(values).then((res) => {
                // SUCCESS ALERT
                setAlertConfig({
                    variant: "primary",
                    text: "Tambah data berhasil!",
                })
            }).catch((err) => {
                // const errMsg = Object.values(err.response?.data?.error) ?? []
                // ERROR ALERT
                setAlertConfig({
                    variant: "danger",
                    text: `Tambah data gagal! (${err.response.data.message})`
                    // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
                });
            }).finally(() => {
                // CLOSE MODAL
                setIsCreateForm(false);
                // TAMPILKAN ALERT
                setShowAlert(true);
                // FETCH DATA DARI SERVER
                getData();
            })
        }

        return (
            <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)}>
                <Formik initialValues={formInitialValues} validationSchema={formValidationSchema} onSubmit={formSubmitHandler}>
                    {
                        ({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Input
                                    label="Kode Grup Aset"
                                    type="text"
                                    name="kode_grup_aset"
                                    placeholder="Kode Grup Aset"
                                    value={values.kode_grup_aset}
                                    onChange={handleChange}
                                    error={
                                        errors.kode_grup_aset && touched.kode_grup_aset && true
                                    }
                                    errorText={errors.kode_grup_aset} />

                                <Input
                                    label="Nama Grup Aset"
                                    type="text"
                                    name="nama_grup_aset"
                                    placeholder="Nama Grup Aset"
                                    value={values.nama_grup_aset}
                                    onChange={handleChange}
                                    error={
                                        errors.nama_grup_aset && touched.nama_grup_aset && true
                                    }
                                    errorText={errors.nama_grup_aset} />

                                <Input
                                    label="Keterangan"
                                    type="text"
                                    name="keterangan"
                                    placeholder="Keterangan"
                                    value={values.keterangan}
                                    onChange={handleChange}
                                    error={
                                        errors.keterangan && touched.keterangan && true
                                    }
                                    errorText={errors.keterangan} />

                                <div className="d-flex justify-content-end">
                                    <ActionButton
                                        type="submit"
                                        variant="primary"
                                        text="Tambah"
                                        className="mt-2 px-4"
                                        loading={isSubmitting} />
                                </div>

                            </form>
                        )}
                </Formik>
            </CreateModal>
        );
    }

    // MODAL UBAH COMPONENT
    const UbahModal = () => {
        const formInitialValues = {
            kode_grup_aset: updateData.kode_grup_aset,
            nama_grup_aset: updateData.nama_grup_aset,
            keterangan: updateData.keterangan,
        }

        // KIRIM UPDATE DATA KE SERVER
        const formSubmitHandler = (values) => {
            const finalValues = {
                id_grup_aset: updateData.id_grup_aset,
                ...values
            }

            // SEND UPDATED DATA TO SERVER
            GrupAsetApi.update(finalValues).then((res) => {
                // SUCCESS ALERT
                setAlertConfig({
                    variant: "primary",
                    text: "Ubah data berhasil!",
                })
            }).catch((err) => {
                // const errMsg = Object.values(err.response?.data?.error) ?? []
                // ERROR ALERT
                setAlertConfig({
                    variant: "danger",
                    text: `Ubah data gagal! (${err.response.data.message})`
                    // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
                });
            }).finally(() => {
                // CLOSE UPDATE MODAL FORM
                setIsUpdateForm(false);
                // SHOW ALERT
                setShowAlert(true);
                // FETCH DATA DARI SERVER
                getData();
            })
        }

        return (
            <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)}>
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={formValidationSchema}
                    onSubmit={formSubmitHandler}>
                    {
                        ({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Input
                                    label="Kode Grup Aset"
                                    type="text"
                                    name="kode_grup_aset"
                                    placeholder="Kode Grup Aset"
                                    value={values.kode_grup_aset}
                                    readOnly={true}
                                    onChange={handleChange}
                                    error={
                                        errors.kode_grup_aset && touched.kode_grup_aset && true
                                    }
                                    errorText={errors.kode_grup_aset} />

                                <Input
                                    label="Nama Grup Aset"
                                    type="text"
                                    name="nama_grup_aset"
                                    placeholder="Nama Grup Aset"
                                    value={values.nama_grup_aset}
                                    onChange={handleChange}
                                    error={
                                        errors.nama_grup_aset && touched.nama_grup_aset && true
                                    }
                                    errorText={errors.nama_grup_aset} />

                                <Input
                                    label="Keterangan"
                                    type="text"
                                    name="keterangan"
                                    placeholder="Keterangan"
                                    value={values.keterangan}
                                    onChange={handleChange}
                                    error={
                                        errors.keterangan && touched.keterangan && true
                                    }
                                    errorText={errors.keterangan} />

                                <div className="d-flex justify-content-end">
                                    <ActionButton
                                        type="submit"
                                        variant="success"
                                        text="Ubah"
                                        className="mt-2 px-4"
                                        loading={isSubmitting} />

                                </div>
                            </form>
                        )}
                </Formik>
            </UpdateModal >
        )
    }

    // MODAL HAPUS COMPONENT
    const HapusModal = () => {
        // SET DATA ID YANG DIHAPUS
        const deleteValue = { id_grup_aset: deleteData.id_grup_aset };

        // MENANGANI DELETE BUTTON LOADING
        const [btnLoading, setBtnLoading] = useState(false);

        // DELETE DATA DARI SERVER
        const deleteDataHandler = () => {
            setBtnLoading(true);

            GrupAsetApi.delete(deleteValue).then(() => {
                // KONFIGURASI ALERT
                setAlertConfig({
                    variant: "primary",
                    text: "Hapus data berhasil!",
                });
            }).catch((err) => {
                // KONFIGURASI ALERT
                setAlertConfig({
                    variant: "danger",
                    text: `Hapus data gagal! (${err.response.data.message})`,
                });
            }).finally(() => {
                // CLOSE MODAL
                setIsDeleteData(false);
                // FETCH DATA DARI SERVER
                getData();
                // TAMPIL ALERT
                setShowAlert(true);
            })
        }

        return (
            <DeleteModal
                show={isDeleteData}
                onHide={() => setIsDeleteData(false)}
                loading={btnLoading}
                onConfirm={deleteDataHandler} />
        )
    }

    // TABLE COMPONENT
    const Table = () => {
        return (
            <>
                <CRUDLayout.Table>
                    <THead>
                        <Tr className="text-center">
                            <ThFixed>No</ThFixed>
                            {/* <ThFixed>Aksi</ThFixed> */}
                            <Th style={{ maxWidth: "1.5rem" }}>Kode Grup Aset</Th>
                            <Th>Nama Grup Aset</Th>
                            <Th>Keterangan</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {data.map((val, index) => {
                            return (
                                <Tr key={val.id_grup_aset}>
                                    <TdFixed >{PageNumber(page, dataLength, index)}</TdFixed>
                                    {/* <TdFixed >
                                        <div className="d-flex justify-content-center">
                                            <UpdateButton
                                                onClick={() => {
                                                    setUpdateData(val);
                                                    setIsUpdateForm(true);
                                                }}
                                            />
                                            <DeleteButton
                                                onClick={() => {
                                                    setDeleteData(val);
                                                    setIsDeleteData(true);
                                                }} />
                                            <Switch
                                                id={val.id_grup_aset}
                                                checked={(val.is_hidden) ? false : true}
                                                onChange={() => changeDataStatus(val.is_hidden, val.id_grup_aset)} />
                                        </div>
                                    </TdFixed> */}
                                    <TdFixed >{val.kode_grup_aset}</TdFixed >
                                    <Td>{val.nama_grup_aset}</Td>
                                    <Td>{val.keterangan}</Td>
                                </Tr>
                            );
                        })}
                    </TBody>
                </CRUDLayout.Table>
                {
                    !isSearching && (
                        <Pagination
                            dataLength={dataLength}
                            dataNumber={page * dataLength - dataLength + 1}
                            dataPage={page * dataLength}
                            dataCount={dataCount}
                            onDataLengthChange={(e) => {
                                setPage(1)
                                setDataLength(e.target.value)
                            }}
                            currentPage={page}
                            totalPage={totalPage}
                            onPaginationChange={({ selected }) => setPage(selected + 1)}
                        />
                    )
                }
            </>

        )
    }

    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <CRUDLayout.HeadSearchSection>
                    {/* <InputSearch placeholder="Cari Grup Aset"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        onSubmit={searchData} /> */}
                </CRUDLayout.HeadSearchSection>

                {/* Button Section */}
                <CRUDLayout.HeadButtonSection>
                    {/* Button Export */}
                    <ExportButton />
                    {/* Button Tambah */}
                    {/* <CreateButton onClick={() => setIsCreateForm(true)} /> */}
                </CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>

            {/* Alert */}
            <Alert show={showAlert}
                showCloseButton={true}
                variant={alertConfig.variant}
                text={alertConfig.text}
                onClose={() => setShowAlert(false)} />

            {
                isLoading ? (<DataStatus loading={isLoading} text="Memuat Data" />) :
                    (!(Array.isArray(data))) ? (<DataStatus text="Tidak dapat memuat data" />)
                        : data.length > 0
                            ? (<Table />)
                            : (<DataStatus text="Tidak ada data" />)
            }

            {/* MODAL */}
            <TambahModal />
            <UbahModal />
            <HapusModal />
        </CRUDLayout>
    )
}

export default GrupAset



