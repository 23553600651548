import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  InfoItemHorizontal
} from 'components'
import { 
  DateConvert 
} from 'utilities'

const InfoSection = ({dataInfo}) => {
  return (
    <Row>
      <Col md>
        <InfoItemHorizontal 
          label="Tgl. Permintaan Job Mix"
          text={dataInfo.tgl_permintaan_job_mix ? DateConvert(new Date(dataInfo.tgl_permintaan_job_mix)).detail : '-'}
        />
        <InfoItemHorizontal 
          label="No. Permintaan Job Mix"
          text={dataInfo.no_permintaan_job_mix}
        />
        <InfoItemHorizontal 
          label="Qty. Permintaan Job Mix"
          text={dataInfo.qty_permintaan_job_mix}
        />
      </Col>
      <Col md>
        <InfoItemHorizontal 
          label="No. Job Mix"
          text={dataInfo.no_job_mix}
        />
        <InfoItemHorizontal 
          label="Item Alat Mesin"
          text={dataInfo.nama_item}
        />
        <InfoItemHorizontal 
          label="Pabrik"
          text={dataInfo.nama_pabrik}
        />
      </Col>
    </Row>
  )
}

export default InfoSection
