import {
  useState,
  useEffect
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import {
  Card
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import {
  DataStatus,
  BackButton,
  PopUpAlert,
  ActionButton
} from 'components'
import {
  TransferProduksiAlatMesinApi
} from 'api'
import {
  InfoSection,
  FormSection,
  TableHistory
} from './Section'

const DummyFetching = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve({
      data: {
        id_permintaan: 1,
        tgl_permintaan_jobmix: '2020-01-01',
        no_permintaan_jobmix: 'PRMAM-0001',
        qty_permintaan_jobmix: '10',
        no_jobmix: 'JBX-0001',
        nama_item: 'Item 01',
        nama_pabrik: 'Pabrik 01',
        nama_satuan: 'Satuan 01',
        history: [
          {
            tgl_transfer: '2020-01-01',
            no_transfer: 'NOTRF-20200101',
            nama_item: 'Item 01',
            qty_transfer: 10,
            nama_satuan: 'Satuan 01'
          },
          {
            tgl_transfer: '2020-01-02',
            no_transfer: 'NOTRF-20200102',
            nama_item: 'Item 01',
            qty_transfer: 20,
            nama_satuan: 'Satuan 01'
          },
          {
            tgl_transfer: '2020-01-03',
            no_transfer: 'NOTRF-20200103',
            nama_item: 'Item 03',
            qty_transfer: 30,
            nama_satuan: 'Satuan 01'
          },
        ]
      }
    })
  }, 500)
})

const DummySave = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    return reject('mantap')
  }, 300);
})

const TransferProduksiAlatMesin = ({setNavbarTitle}) => {
  const history = useHistory()
  const {id} = useParams()
  const [dataInfo, setDataInfo] = useState({})
  const [dataHistory, setDataHistory] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: '',
    title: '',
    text: '',
    hide: () => setAlertConfig({...alertConfig, show: false})
  })


  const formInitialValues = {
    tanggal: '',
    nomor: '',
    nama_item: dataInfo.nama_item,
    qty: dataInfo.qty_permintaan_job_mix,
    satuan: dataInfo.satuan
  }
  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string()
      .required('Pilih tanggal'),
    nomor: Yup.string()
      .required('Nomor tidak boleh kosong, pilih tgl untuk menentukan nomor'),
      qty: Yup.number()
        .required('Masukan qty')
        .positive('Qty harus lebih besar daripada 0')
        .typeError('Qty harus berupa angka')
  })
  const formSubmitHandler = (values, {setSubmitting}) => {
    const finalValues = {
      id_jobmix_permintaan: id,
      tgl_jobmix_transfer_alat_mesin: values.tanggal,
      id_item_aset: dataInfo.id_item,
      qty_transfer: values.qty,
      id_pabrik: dataInfo.id_pabrik
    }

    TransferProduksiAlatMesinApi.save(finalValues)
      .then(() => {
        setAlertConfig({
          ...alertConfig,
          show: true,
          type: 'success',
          title: 'Simpan Data Berhasil',
          text: 'Data transfer alat & mesin berhasil disimpan!',
          hide: () => history.push('/transaksi/transfer-produksi-alat-mesin')
        })
      })
      .catch(() => {
        setSubmitting(false)
        setAlertConfig({
          ...alertConfig,
          show: true,
          type: 'danger',
          title: 'Simpan Data Gagal',
          text: 'Data transfer alat & mesin gagal disimpan!',
          hide: () => setAlertConfig({
            ...alertConfig,
            show: false
          })
        })
      })
  }

  const mappingDataInfo = data => {
    return {
      id_permintaan: data.id_jobmix_permintaan ?? null,
      tgl_permintaan_job_mix: data.tgl_jobmix_permintaan ?? null,
      no_permintaan_job_mix: data.no_jobmix_permintaan ?? '-',
      qty_permintaan_job_mix: data.qty_permintaan ? parseFloat(data.qty_permintaan) : 0,
      no_job_mix: data.no_jobmix ?? '-',
      id_item: data.id_item_aset ?? null,
      nama_item: data.nama_item_aset ?? '-',
      satuan: data.nama_satuan_pakai ?? '-',
      id_pabrik: data.id_pabrik ?? null,
      nama_pabrik: data.nama_pabrik ?? '-'
    }
  }

  const mappingDataHistory = data => data.map(val => {
    return {
      tgl_transfer: val.tgl_jobmix_transfer_alat_mesin ?? null,
      no_transfer: val.no_jobmix_transfer_alat_mesin ?? '-',
      nama_item: val.nama_item_aset ?? '-',
      qty_transfer: val.qty_transfer ? parseFloat(val.qty_transfer) : 0,
      satuan: val.nama_satuan_pakai ?? ''
    }
  })

  const getInitialData = () => {
    TransferProduksiAlatMesinApi.getSingle({id_jobmix_permintaan: id})
      .then(res => {
        const rawDataInfo = res?.data.data
        const rawDataHistory = rawDataInfo.history
        const mapDataInfo = mappingDataInfo(rawDataInfo ?? {})
        const mapDataHistory = mappingDataHistory(rawDataHistory ?? [])

        setDataInfo(mapDataInfo)
        setDataHistory(mapDataHistory)
        setFetchingStatus({
          loading: false,
          success: true
        })
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  useEffect(() => {
    setNavbarTitle('Tambah Data Transfer Alat & Mesin Job Mix')
    getInitialData()
  }, [])

  // Tampilan saat loading atau gagal memuat data
  if (fetchingStatus.loading || !fetchingStatus.success) {
    return (
      <Card>
         <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Permintaan Job Mix </b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading 
            ? <DataStatus loading text="Memuat data . . ." />
            : <DataStatus text="Tidak ada data" />
          }
        </Card.Body>
      </Card>
    )
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Permintaan Job Mix </b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {/* Menampilkan bagian info */}
              <InfoSection dataInfo={dataInfo} />
              <hr />

              {/* Menampilkan bagian form */}
              <FormSection formik={formik} />
              <hr />
              
              {/* Menampilkan bagian tabel */}
              <TableHistory dataHistory={dataHistory} />

              {/* Menampilkan tombol simpan */}
              <div className="d-flex justify-content-end mt-5">
                <ActionButton 
                  type="submit"
                  text="Simpan Data Transfer"
                  className="px-4"
                  loading={formik.isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </Card.Body>

      {/* Alert */}
      <PopUpAlert 
        show={alertConfig.show}
        onConfirm={alertConfig.hide}
        onHide={alertConfig.hide}
        title={alertConfig.title}
        text={alertConfig.text}
        type={alertConfig.type}
      />
    </Card>
  )
}

export default TransferProduksiAlatMesin
