import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import _ from "lodash";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  DeleteModal,
  Alert,
  Pagination,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DeleteButton,
  ReadModal,
  ReadButton,
  BackButton,
} from "../../../../../components";
import { AsetInventarisKantorApi } from "../../../../../api/ASM/Master/ItemAsetApi";
import { PageNumber, RupiahConvert } from "../../../../../utilities";
import { Col, Row, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

function AsetInventarisKantor({ setNavbarTitle }) {
  const title = "Aset Inventaris";
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [dataCount, setDataCount] = useState(0);
  const [isDetailForm, setIsDetailForm] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis anggaran
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [idDetail, setIdDetail] = useState(0);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: "primary",
    text: "",
  });

  const history = useHistory();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    // request data ke server
    Axios.all([AsetInventarisKantorApi.getPage(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setDataCount(res.data.data_count);
          setTotalPage(res.data.total_page);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfigSearch({
            data: true,
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: "",
            text: "",
          });
        }

        setIsLoading(false)
      });
  };

  // fungsi show/ hide
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_item_aset: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? AsetInventarisKantorApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : AsetInventarisKantorApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData());
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_aset: deleteData.id_item_aset };
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      AsetInventarisKantorApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // FETCH DATA DARI SERVER
          getData();
          // TAMPIL ALERT
          setShowAlert(true);
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Aset Inventaris : {deleteData.kode_item_aset}</div>
        <div>Nama Aset Inventaris : {deleteData.nama_item_aset}</div>
      </DeleteModal>
    );
  };

  const DetailModal = () => {
    const [dataDetail, setDataDetail] = useState([]);
    const getDetail = () => {
      if (idDetail !== 0) {
        AsetInventarisKantorApi.getSingle(idDetail)
          .then((res) => {
            setDataDetail(res.data.data);
          })
          .catch((err) => {
            setAlertConfig({
              variant: "danger",
              text: `Gagal memuat Data ${err.response.data.message}`,
            });
          })
          .finally(() => { });
      }
    };

    useEffect(() => {
      getDetail();
    }, []);

    const ShowData = ({ title, text, line }) => (
      <div className="mb-2">
        <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
          {title}
        </small>
        <div
          className="text-dark"
          style={{
            fontWeight: 500,
          }}
        >
          {text ? text : "-"}
        </div>
        {line && <hr className="my-2" />}
      </div>
    );
    return (
      <ReadModal show={isDetailForm} onHide={() => setIsDetailForm(false)} title={title} size="xl">
        <Modal.Body>
          <>
            <Row>
              <Col lg>
                <ShowData title="Grup Aset" text="50 - Inventaris" line={true} />
                <ShowData title="Kode Item Aset" text={dataDetail.kode_item_aset} line={true} />
                <ShowData title="Tahun Perolehan" text={dataDetail.tahun_perolehan} line={true} />
                <ShowData title="Golongan Aset" text={dataDetail.nama_golongan_aset} line={true} />
                <ShowData title="Jenis Aset" text={dataDetail.nama_jenis_aset} line={true} />
                <ShowData title="Kategori Aset" text={dataDetail.nama_kategori_aset} line={true} />
              </Col>
              <Col lg>
                <ShowData title="Nama Item Aset" text={dataDetail.nama_item_aset} line={true} />
                <ShowData title="Kondisi Aset" text={dataDetail.nama_kondisi_aset} line={true} />
                <ShowData
                  title="Nilai Perolehan"
                  text={RupiahConvert(String(dataDetail.nilai_perolehan)).detail}
                  line={true}
                />
                <ShowData
                  title="Umur Ekonomis"
                  text={dataDetail.umur_ekonomis_bulan + " Tahun"}
                  line={true}
                />
                <ShowData
                  title="Penyusutan Perbulan"
                  text={RupiahConvert(String(dataDetail.penyusutan_per_bulan)).detail}
                  line={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ShowData title="Lokasi Aset" text={dataDetail.lokasi} line={true} />
              </Col>
            </Row>
            <Row>
              <Col lg>
                <ShowData title="Penanggung Jawab" text={dataDetail.nama_karyawan} line={true} />
              </Col>
              <Col lg>
                <ShowData title="Pengguna" text={dataDetail.nama_unit_organisasi} line={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ShowData title="Keterangan" text={dataDetail.keterangan} line={true} />
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <BackButton
            onClick={() => {
              setIsDetailForm(false);
            }}
          />
        </Modal.Footer>
      </ReadModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode Item Aset</ThFixed>
            <Th>Nama Item Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() => {
                      history.push(
                        `/asm/master/item-aset/aset-inventaris-kantor/detail/${val.id_item_aset}`
                      );
                    }}
                  />
                  <UpdateButton
                    onClick={() => {
                      history.push(
                        `/asm/master/item-aset/aset-inventaris-kantor/ubah/${val.id_item_aset}`
                      );
                    }}
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                  <Switch
                    id={toString(index + 1)}
                    checked={val.is_hidden === false ? true : false}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_item_aset)}
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.kode_item_aset}</TdFixed>
              <Td>{val.nama_item_aset}</Td>
              <Td>{val.nama_kategori_aset}</Td>
              <Td>{val.nama_jenis_aset}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={dataLength}
        dataNumber={page * dataLength - dataLength + 1}
        dataPage={page * dataLength}
        dataCount={dataCount}
        onDataLengthChange={(e) => {
          setPage(1)
          setDataLength(e.target.value)
        }}
        currentPage={page}
        totalPage={totalPage}
        onPaginationChange={({ selected }) => setPage(selected + 1)}
      />
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value)
                    setPage(1)
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <Link to="/asm/master/item-aset/aset-inventaris-kantor/tambah">
            <CreateButton />
          </Link>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setAlertConfigSearch({
          data: false,
          variant: "primary",
          text: "",
        })}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      <HapusModal />
      <DetailModal />
    </CRUDLayout>
  );
}

export default AsetInventarisKantor;
