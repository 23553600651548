import { Modal } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { ActionButton } from "..";
import { IoAlertCircleOutline } from "react-icons/io5";

const AlertModal = ({ show, onHide, message, link, textColor, title, status }) => {
  return (
    <div>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title className={textColor}>
            <h6 className="mb-0 text-capitalize">
              <IoAlertCircleOutline className="mb-1 mr-2" size={20} />
              {title}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 className="mb-1 text-capitalize">{message}</h5>
          <div className="mt-3">
            {status === true ? (
              <Link to={link}>
                <ActionButton variant="outline-secondary" text="Kembali" onClick={onHide} />
              </Link>
            ) : (
              <ActionButton variant="outline-secondary" text="Batal" onClick={onHide} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AlertModal;
