// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import { AsetBangunanApi } from "../../../../../../api";

// Component
import _ from "lodash";
import {
  ActionButton, DataStatus, Alert, Input,
  AlertModal, CRUDLayout, Th, ThFixed,
  THead, TBody, Tr, Td, TdFixed
} from "../../../../../../components";
import { RupiahConvert } from "../../../../../../utilities";
import { Col, Row } from "react-bootstrap";

const PenyusutanAset = ({tipe, data, setPage}) => {
  const [dataInduk, setDataInduk] = useState({
    penyusutan_saldo_menurun: [],
  });
  
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const getDataPenyusutan = (value, setFieldValue) => {
    AsetBangunanApi.penyusutan(value)
      .then(res => {
        setDataInduk({
          penyusutan_saldo_menurun: res.data.penyusutan_saldo_menurun
        })

        setFieldValue('penyusutan_garis_lurus_per_tahun', RupiahConvert(parseInt(res.data.penyusutan_garis_lurus_per_tahun).toString()).detail)
        setFieldValue('penyusutan_garis_lurus_per_bulan', RupiahConvert(parseInt(res.data.penyusutan_garis_lurus_per_bulan).toString()).detail)
      })
  };

  useEffect(() => {
    setDataInduk({
      ...dataInduk,
      penyusutan_saldo_menurun: data.penyusutan
    })
  }, [])

  const ColumnData = ({title, className, children}) => (
    <div className={className ? className : 'mb-4'}>
      <h6 
        className="ml-1 text-dark"
        style={{
          fontSize: '17px',
          marginBottom: '12.5px'
        }}
      >
        <b>{title}</b>
      </h6>
      <div className="bg-white p-2 px-3 shadow-sm rounded">
        {children}
      </div>
    </div>
  )

  const [saveAlert, setSaveAlert] = useState(false);
  const [saveAlertConfig, setsaveAlertConfig] = useState({
    textColor: "",
    title: "",
    message: "",
    status: "",
  });

  const formInitialValues = {
    id_item_aset: data.id_item_aset,
    harga_perolehan: data.harga_perolehan == null ? "" : RupiahConvert(parseInt(data.harga_perolehan).toString()).detail,
    umur_ekonomis_tahun: data.umur_ekonomis_tahun == null || data.umur_ekonomis_tahun == "" ? "0" : data.umur_ekonomis_tahun,
    penyusutan_garis_lurus_per_tahun: data.penyusutan_garis_lurus_per_tahun == null || data.penyusutan_garis_lurus_per_tahun == "" ? RupiahConvert("0").detail : RupiahConvert(parseInt(data.penyusutan_garis_lurus_per_tahun).toString()).detail,
    penyusutan_garis_lurus_per_bulan: data.penyusutan_garis_lurus_per_bulan == null || data.penyusutan_garis_lurus_per_bulan == "" ? RupiahConvert("0").detail : RupiahConvert(parseInt(data.penyusutan_garis_lurus_per_bulan).toString()).detail,
    nama_jenis_aset: data.nama_jenis_aset,
    nama_golongan_aset: data.nama_golongan_aset
  };

  // skema validasi form
  const formValidationSchema = Yup.object().shape({
    harga_perolehan: Yup.string().required("Harga Perolehan wajib diisi"),
    penyusutan_garis_lurus_per_tahun: Yup.string().required("Penyusutan Garis Lurus Per Tahun tidak boleh kosong"),
    penyusutan_garis_lurus_per_bulan: Yup.string().required("Penyusutan Garis Lurus Per Bulan tidak boleh kosong")
  });

  const formSubmitHandler = (values) => {
    let harga_perolehan = RupiahConvert(values.harga_perolehan).default == "" ? 0 : RupiahConvert(values.harga_perolehan).default
    let penyusutan_garis_lurus_per_tahun = RupiahConvert(values.penyusutan_garis_lurus_per_tahun).default == "" ? 0 : RupiahConvert(values.penyusutan_garis_lurus_per_tahun).default
    let penyusutan_garis_lurus_per_bulan = RupiahConvert(values.penyusutan_garis_lurus_per_bulan).default == "" ? 0 : RupiahConvert(values.penyusutan_garis_lurus_per_bulan).default

    AsetBangunanApi.penyusutan({
      harga_perolehan: harga_perolehan,
      umur_ekonomis: values.umur_ekonomis_tahun,
    })
      .then(res => {
        AsetBangunanApi.updatePenyusutan({
          id_item_aset: values.id_item_aset,
          harga_perolehan: harga_perolehan,
          penyusutan_saldo_menurun: res.data.penyusutan_saldo_menurun,
          penyusutan_garis_lurus_per_tahun: penyusutan_garis_lurus_per_tahun,
          penyusutan_garis_lurus_per_bulan: penyusutan_garis_lurus_per_bulan,
        })
          .then(() => {
            if (tipe == "tambah") {
              setPage(3)
            } else {
              setsaveAlertConfig({
                textColor: "text-success",
                title: "Simpan data berhasil",
                message: "Data berhasil di ubah",
                status: true,
              });
            }
          })
          .catch((err) => {
            setsaveAlertConfig({
              textColor: "text-danger",
              title: "Tambah data gagal",
              message: err.response.data.message,
              status: false,
            });
          })
          .finally(() => {
            setSaveAlert(true);
          });
      })
  };

  return (
    <>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <ColumnData title="Data Dasar Aset">
                <Row>
                  <Col lg>
                    <Input
                      label="Jenis Aset"
                      type="text"
                      name="nama_jenis_aset"
                      value={values.nama_jenis_aset}
                      readOnly={true}
                      onChange={handleChange}
                    />

                    <Input
                      label="Harga Perolehan Aset"
                      type="text"
                      name="harga_perolehan"
                      value={values.harga_perolehan}
                      onChange={(e) => {
                        let nilai = e.target.value;
                        let number = nilai.replace(/[^\d]/g, "").toString();
                        setFieldValue('harga_perolehan', RupiahConvert(number).detail)
                      }}
                      onBlur={(e) => {
                        let number = e.target.value.replace(/[^\d]/g, "").toString();
                        let angka = RupiahConvert(number).default;

                        angka != "" && values.umur_ekonomis_tahun != "0"  && getDataPenyusutan({
                          harga_perolehan: angka,
                          umur_ekonomis: values.umur_ekonomis_tahun,
                        }, setFieldValue)
                      }}
                      error={errors.harga_perolehan && touched.harga_perolehan && true}
                      errorText={errors.harga_perolehan}
                      readOnly={tipe != "detail" ? false : true}
                    />

                    <Input
                      label="Penyusutan Garis Lurus Per Tahun"
                      type="text"
                      name="penyusutan_garis_lurus_per_tahun"
                      value={values.penyusutan_garis_lurus_per_tahun}
                      readOnly={true}
                      error={errors.penyusutan_garis_lurus_per_tahun && touched.penyusutan_garis_lurus_per_tahun && true}
                      errorText={errors.penyusutan_garis_lurus_per_tahun}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col lg>
                    <Input
                      label="Golongan Aset"
                      type="text"
                      name="nama_golongan_aset"
                      value={values.nama_golongan_aset}
                      readOnly={true}
                      onChange={handleChange}
                    />

                    <Input
                      label="Umur Ekonomis (Tahun)"
                      type="text"
                      name="umur_ekonomis_tahun"
                      value={values.umur_ekonomis_tahun}
                      readOnly={true}
                      onChange={handleChange}
                    />

                    <Input
                      label="Penyusutan Garis Lurus Per Bulan"
                      type="text"
                      name="penyusutan_garis_lurus_per_bulan"
                      value={values.penyusutan_garis_lurus_per_bulan}
                      readOnly={true}
                      error={errors.penyusutan_garis_lurus_per_bulan && touched.penyusutan_garis_lurus_per_bulan && true}
                      errorText={errors.penyusutan_garis_lurus_per_bulan}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <small>Penyusutan Saldo Menurun</small>
                <CRUDLayout.Table>
                  <THead>
                    <Tr>
                      <Th>Tahun Ke</Th>
                      <Th>Penyusutan Per Tahun</Th>
                      <Th>Penyusutan Per Bulan</Th>
                    </Tr>
                  </THead>

                  <TBody>
                    {dataInduk.penyusutan_saldo_menurun.length > 0 ? 
                      dataInduk.penyusutan_saldo_menurun.map((val, index) => {
                        return (
                          <Tr key={index}>
                            <TdFixed>{val.tahun_ke}</TdFixed>
                            <Td>
                              <div className="text-right">{RupiahConvert(parseInt(val.penyusutan_saldo_menurun_per_tahun).toString()).detail}</div>
                            </Td>
                            <Td>
                              <div className="text-right">{RupiahConvert(parseInt(val.penyusutan_saldo_menurun_per_bulan).toString()).detail}</div>
                            </Td>
                          </Tr>
                        )
                      })
                    : 
                      <Tr>
                        <TdFixed colSpan="3">Tidak ada data</TdFixed>
                      </Tr>
                    }
                  </TBody>
                </CRUDLayout.Table>
              </ColumnData>
              {tipe != "detail" && 
                <div className="text-right">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text={tipe.toUpperCase()}
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              }
            </form>
          )}
        </Formik>
        <AlertModal
          show={saveAlert}
          onHide={() => setSaveAlert(false)}
          textColor={saveAlertConfig.textColor}
          title={saveAlertConfig.title}
          message={saveAlertConfig.message}
          link="/asm/master/item-aset/aset-bangunan"
          status={saveAlertConfig.status}
        />
      </>
    </>
  );
};

export default PenyusutanAset;
