import {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  Input,
  DatePicker,
} from 'components'
import { 
  DateConvert 
} from 'utilities';
import {
  TransferProduksiAlatMesinApi
} from 'api'

const FormSection = ({formik}) => {
  const today = DateConvert(new Date()).default
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const {values, errors, touched, setValues, handleChange} = formik

  const getNomorHandler = date => {
    setIsNomorLoading(true)

    TransferProduksiAlatMesinApi.getNomor({tanggal: date})
      .then(res => {
        const nomor = res.data.data
        setValues({
          ...values,
          nomor: nomor,
          tanggal: date
        })
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: '',
          tanggal: ''
        })
      })
      .finally(() => setIsNomorLoading(false))
  }

  useEffect(() => {
    getNomorHandler(today)
  }, [])

  return (
    <>
      <Row>
        <Col md>
          <DatePicker 
            label="Tgl. Transfer Alat & Mesin"
            placeholderText={isNomorLoading ? 'Memuat tanggal' : 'Pilih tgl transfer'}
            selected={values.tanggal ? new Date(values.tanggal) : ''}
            onChange={date => getNomorHandler(DateConvert(date).default)}
            error={Boolean(errors.tanggal && touched.tanggal)}
            errorText={Boolean(errors.tanggal && touched.tanggal) && errors.tanggal}
          />
        </Col>
        <Col md>
          <Input 
            readOnly
            label="No. Transfer Alat & Mesin"
            placeholder="Pilih tgl untuk menentukan nomor"
            value={isNomorLoading ? 'Memuat nomor . . .' : values.nomor}
            error={Boolean(errors.nomor && touched.nomor)}
            errorText={Boolean(errors.nomor && touched.nomor) && errors.nomor}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <Input 
            readOnly
            label="Item Alat Mesin"
            placeholder="Pilih item alat mesin"
            value={values.nama_item}
          />
        </Col>
        <Col md>
          <Row>
            <Col md>
              <Input 
                type="number"
                label="Qty. Transfer"
                placeholder="Masukan qty. transfer"
                name="qty"
                value={values.qty}
                onChange={handleChange}
                error={Boolean(errors.qty && touched.qty)}
                errorText={Boolean(errors.qty && touched.qty) && errors.qty}
              />
            </Col>
            <Col md>
              <Input 
                readOnly
                label="Satuan Transfer"
                placeholder="Masukan satuan transfer"
                value={values.satuan}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default FormSection
