import React, { 
  useState, 
  useEffect 
} from "react"
import { 
  useHistory, 
  useLocation 
} from "react-router-dom"
import { 
  Row, 
  Col, 
  ModalBody
} from "react-bootstrap"
import {
  IoAddOutline,
  IoEyeOutline
} from 'react-icons/io5'
import _ from "lodash"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  BackButton,
  InfoItemHorizontal,
  Modal
} from "components"
import { 
  DateConvert, 
  PageNumber as TableNumber 
} from "utilities"
import { 
  TransferProduksiAlatMesinApi
} from "api"

const ListPermintaanProduksiTransferAlatMesin = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataPermintaan, setDataPermintaan] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    idPermintaan: ''
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    TransferProduksiAlatMesinApi.getPermintaan({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPermintaan(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  const checkPermintaanTerpenuhi = (permintaan, transfer) => {
    const qtyPermintaan = permintaan ? parseFloat(permintaan) : 0
    const qtyTransfer = transfer ? parseFloat(transfer) : 0
    
    if (qtyTransfer >= qtyPermintaan) {
      return true
    }

    return false
  }

  useEffect(() => {
    setNavbarTitle("List Permintaan Job Mix")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ])

  const DetailModal = () => {
    const idPermintaan= modalConfig.idPermintaan
    const [dataInfoModal, setDataInfoModal] = useState({})
    const [dataHistoryModal, setDataHistoryModal] = useState([])
    const [modalFetchingStatus, setModalFetchingStatus] = useState({
      loading: true,
      success: false
    })

    const mappingDataInfo = data => {
      return {
        id_permintaan: data.id_jobmix_permintaan ?? null,
        tgl_permintaan_job_mix: data.tgl_jobmix_permintaan ?? null,
        no_permintaan_job_mix: data.no_jobmix_permintaan ?? '-',
        qty_permintaan_job_mix: data.qty_permintaan ? parseFloat(data.qty_permintaan) : 0,
        no_job_mix: data.no_jobmix ?? '-',
        id_item: data.id_item_aset ?? null,
        nama_item: data.nama_item_aset ?? '-',
        satuan: data.nama_satuan_pakai ?? '-',
        id_pabrik: data.id_pabrik ?? null,
        nama_pabrik: data.nama_pabrik ?? '-'
      }
    }
  
    const mappingDataHistory = data => data.map(val => {
      return {
        tgl_transfer: val.tgl_jobmix_transfer_alat_mesin ?? null,
        no_transfer: val.no_jobmix_transfer_alat_mesin ?? '-',
        nama_item: val.nama_item_aset ?? '-',
        qty_transfer: val.qty_transfer ? parseFloat(val.qty_transfer) : 0,
        satuan: val.nama_satuan_pakai ?? ''
      }
    })
  
    const getInitialDataModal = () => {
      TransferProduksiAlatMesinApi.getSingle({id_jobmix_permintaan: idPermintaan})
        .then(res => {
          const rawDataInfo = res?.data.data
          const rawDataHistory = rawDataInfo.history
          const mapDataInfo = mappingDataInfo(rawDataInfo ?? {})
          const mapDataHistory = mappingDataHistory(rawDataHistory ?? [])
  
          setDataInfoModal(mapDataInfo)
          setDataHistoryModal(mapDataHistory)
          setModalFetchingStatus({
            loading: false,
            success: true
          })
        })
        .catch(() => {
          setModalFetchingStatus({
            loading: false,
            success: false
          })
        })
    }

    const InfoItemModal = () => (
      <Row>
        <Col md>
          <InfoItemHorizontal 
            label="Tgl. Permintaan Job Mix"
            text={dataInfoModal.tgl_permintaan_job_mix ? DateConvert(new Date(dataInfoModal.tgl_permintaan_job_mix)).detail : '-'}
          />
          <InfoItemHorizontal 
            label="No. Permintaan Job Mix"
            text={dataInfoModal.no_permintaan_job_mix}
          />
          <InfoItemHorizontal 
            label="Qty. Permintaan Job Mix"
            text={dataInfoModal.qty_permintaan_job_mix}
          />
        </Col>
        <Col md>
          <InfoItemHorizontal 
            label="No. Job Mix"
            text={dataInfoModal.no_job_mix}
          />
          <InfoItemHorizontal 
            label="Item Alat Mesin"
            text={dataInfoModal.nama_item}
          />
          <InfoItemHorizontal 
            label="Pabrik"
            text={dataInfoModal.nama_pabrik}
          />
        </Col>
      </Row>
    )

    const TableHistoryModal = () => (
      <>
        <div className="py-1">
          <b>History Transfer Alat & Mesin Job Mix</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed>No</ThFixed>
              <Th width={100}>Tgl. Transfer</Th>
              <Th width={100}>No. Transfer</Th>
              <Th>Item Alat & Mesin</Th>
              <Th width={100}>Qty. Transfer</Th>
            </tr>
          </thead>
          <tbody>
            {dataHistoryModal && dataHistoryModal.length > 0
              ? dataHistoryModal.map((val, index) => (
                <tr>
                  <TdFixed textCenter className="pb-2">{index + 1}</TdFixed>
                  <Td>{val.tgl_transfer ? DateConvert(new Date(val.tgl_transfer)).defaultDMY : ''}</Td>
                  <Td>{val.no_transfer}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight>{val.qty_transfer} {val.satuan}</Td>
                </tr>
              ))
              : <tr>
                  <Td colSpan={5}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
            }
          </tbody>
        </Table>
      </>
    )

    useEffect(() => {
      getInitialDataModal()
    }, [])

    return (
      <Modal
        closeButton
        size="lg"
        title="Detail Permintaan Job Mix"
        show={modalConfig.show}
        onHide={() => setModalConfig({
          show: false,
          idPermintaan: ''
        })}
      > 
        <ModalBody>
          {modalFetchingStatus.loading
            ? <DataStatus loading text="Memuat data . . ." />
            : modalFetchingStatus.success
              ? <>
                  <InfoItemModal />
                  <hr />
                  <TableHistoryModal />
                </>
              : <DataStatus text="Data gagal dimuat!" />
          }
        </ModalBody>
      </Modal>
    )
  }

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th noPadding width={80}>Tgl. Permintaan</Th>
            <Th noPadding width={120}>No. Permintaan</Th>
            <Th>Item Permintaan</Th>
            <Th noPadding width={120}>Qty. Permintaan</Th>
            <Th>Pabrik</Th>
            <Th width={80}>Status</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataPermintaan.map((val, index) => (
            <tr key={index}>
              <Td textCenter>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
              <Td>{val.tgl_jobmix_permintaan ? DateConvert(new Date(val.tgl_jobmix_permintaan)).defaultDMY : "-"}</Td>
              <Td>{val.no_jobmix_permintaan ?? "-"}</Td>
              <Td>{val.nama_item_aset ?? "-"}</Td>
              <Td>{val.qty_permintaan ? `${parseFloat(val.qty_permintaan)} ${val.nama_satuan_pakai ?? ''}` : '-'}</Td>
              <Td>{val.nama_pabrik ?? "-"}</Td>
              <Td className="text-capitalize">{val.status_transfer ?? "-"}</Td>
              <Td>
              {checkPermintaanTerpenuhi(val.qty_permintaan, val.qty_tertransfer) 
                ? <ActionButton 
                    size="sm"
                    variant="success"
                    text="Detail"
                    className="col"
                    onClick={() => setModalConfig({
                      show: true,
                      idPermintaan: val.id_jobmix_permintaan
                    })}
                  />
                : <ActionButton 
                    size="sm"
                    variant="primary"
                    text="Transfer"
                    className="col"
                    onClick={() => history.push('/transaksi/transfer-produksi-alat-mesin/transfer/' + val.id_jobmix_permintaan)}
                  />
              }
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    )

    if (!dataPermintaan || dataPermintaan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
      
      {/* Modal */}
      {modalConfig.show && <DetailModal />}
    </CRUDLayout>
  )
}

export default ListPermintaanProduksiTransferAlatMesin
